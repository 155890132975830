import {Col, Row, Select} from 'antd';
import CustomButton from "../../general/CustomButton";
import {useLocation, useNavigate, useOutletContext} from "react-router";

const NinthStep = ({question})=>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/10')
        }else{
            navigate('/questions/10')
        }
    }


    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    return (
        <div>
            <div>{question}</div>
            <Row gutter={[12,12]}>
                <Col lg={8}>
                    <Select
                        size={'large'}
                        placeholder={'آخرین مدرک تحصیلی'}
                        defaultValue={5}
                        onChange={handleChange}
                        options={[
                            {
                                value: 5,
                                label: 'دیپلم',
                            },
                            {
                                value: 6,
                                label: 'لیسانس',
                            },
                            {
                                value: 7.5,
                                label: 'دکتری',
                            },
                        ]}
                    />
                </Col>
                <Col lg={8}>
                    <Select
                        size={'large'}
                        placeholder={'رشته تحصیلی'}
                        defaultValue={6}
                        onChange={handleChange}
                        options={[
                            {
                                value: 5,
                                label: 'مهندسی برق',
                            },
                            {
                                value: 6,
                                label: 'پزشکی',
                            },
                            {
                                value: 7.5,
                                label: 'روانشناسی',
                            },
                        ]}
                    />
                </Col>
                <Col lg={8}>
                    <Select
                        size={'large'}
                        placeholder={'سال فارغ التحصیلی '}
                        defaultValue={6}
                        onChange={handleChange}
                        options={[
                            {
                                value: 5,
                                label: '1392 (2013)',
                            },
                            {
                                value: 6,
                                label: '1400 (2021)',
                            },
                            {
                                value: 7.5,
                                label: '1402 (2023)',
                            },
                        ]}
                    />
                </Col>
            </Row>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default NinthStep