import {Radio, Select, Checkbox, Row, Col, List, Modal} from 'antd';
import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import CustomButton from "../../general/CustomButton";
import './tenthStep.scss'
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";

const TenthStep = ({question}) => {

    const [value, setValue] = useState('yes');
    const [showResumeModal,setShowResumeModal]=useState(false)
    const [isChecked,setIsChecked] = useState(false)
    const [data,setData]= useState([
        {
            id:1,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:2,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:3,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:4,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:5,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:6,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
    ])

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/11')
        }else{
            navigate('/questions/11')
        }
    }



    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onChangeCheckbox = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setIsChecked(true)
        if (e.target.checked) {
            setShowResumeModal(true)
        }
    }

    const deleteListItemHandler = (id) =>{
        let items = data.filter(item => item.id !== id)
        setData(items)
    }

    const handleOk = () => {
        setShowResumeModal(false);
    };
    const handleCancel = () => {
        setShowResumeModal(false);
        setIsChecked(false)
    };


    return (
        <div>
            <Modal open={showResumeModal} footer={false}>
                <div style={{textAlign:'center',marginTop:'1rem'}}>
                    <h4>سابقه کار خیلی میتونه کمک کنه،</h4>
                    <h4>مطمئنی سابقه کار نداری؟</h4>
                    <div style={{margin:'2rem  0 1rem'}}>
                        <CustomButton onClick={handleOk} text={'بله'} ghost={true} width={100}/>
                        <CustomButton onClick={handleCancel} text={'خیر'} width={100} style={{marginRight:'15px'}}/>
                    </div>
                </div>
            </Modal>
            <div>{question}</div>
            <Row gutter={[12, 12]}>
                <Col lg={12}>
                    <Select
                        size={'large'}
                        placeholder={'از سال'}
                        // defaultValue={5}
                        onChange={handleChange}
                        options={[
                            {
                                value: 5,
                                label: '1392 (2013)',
                            },
                            {
                                value: 6,
                                label: '1400 (2021)',
                            },
                            {
                                value: 7.5,
                                label: '1402 (2023)',
                            },
                        ]}
                    />
                </Col>
                <Col lg={12}>
                    <Select
                        size={'large'}
                        placeholder={'تا سال'}
                        // defaultValue={6}
                        onChange={handleChange}
                        options={[
                            {
                                value: 5,
                                label: '1392 (2013)',
                            },
                            {
                                value: 6,
                                label: '1400 (2021)',
                            },
                            {
                                value: 7.5,
                                label: '1402 (2023)',
                            },
                        ]}
                    />
                </Col>
                <Col lg={24}>
                    <Select
                        size={'large'}
                        placeholder={'زمینه شغلی '}
                        // defaultValue={''}
                        onChange={handleChange}
                        options={[
                            {
                                value: 5,
                                label: 'مهندسی برق',
                            },
                            {
                                value: 6,
                                label: 'پزشکی',
                            },
                            {
                                value: 7.5,
                                label: 'روانشناسی',
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Radio.Group onChange={onChange} value={value} style={{display: 'flex'}}>
                <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'تمام وقت'} width={150}/></div>
                <div style={{marginRight: '1rem'}}><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                                                      text={'نیمه وقت'} width={150}/></div>
            </Radio.Group>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <div>
                    <CustomButton ghost={true} width={150} text={'افزودن سابقه جدید'}/>
                </div>
                <div style={{textAlign: 'right', marginTop: '1rem'}}><Checkbox checked={isChecked} disabled={data.length > 0} onChange={onChangeCheckbox}>سابقه کار
                    ندارم</Checkbox></div>
            </div>

            {data.length > 0 && <div
                id="scrollableDiv"
                style={{
                    height: 100,
                    marginTop: '1rem',
                    overflow: 'auto',
                    padding: '0 16px',
                    // border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
            >
                <List
                    dataSource={data}
                    bordered={false}
                    renderItem={(item) => (
                        <List.Item>
                            <div className={'list-item-wrapper'}>
                                <div>{item.title}{" " + `(${item.type})`}</div>
                                <div className={'delete-date-container'}>
                                    <div>{item.fromDate + " - " + item.toDate}</div>
                                    <div id={'delete'} onClick={()=>deleteListItemHandler(item.id)}><DeleteOutlined className={'delete-icon'}/></div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>}
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default TenthStep