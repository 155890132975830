import {Radio, Select, Checkbox, Row, Col, List, Modal} from 'antd';
import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";

const NineteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showResumeModal, setShowResumeModal] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [children, setChildren] = useState(null)
    const [childrenNumber, setChildrenNumber] = useState(null)
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/20')
        }else{
            navigate('/questions/20')
        }
    }

    const handleChange = (value) => {
        setValue(true)
        setChildrenNumber(value)
        console.log(`selected ${value}`);
        let childrenList = []
        for (let i = 0; i < value; i++) {
            childrenList.push({
                id: i,
                age: null,
                number: `${i === 0 ? 'اول' : i === 1 ? 'دوم' : i === 2 ? 'سوم' : 'چهارم'}`
            })
        }
        setChildren(childrenList)
    };

    const onChange = (e) => {
        console.log(e.target.value)
        setValue(e.target.value);
        if (!e.target.value){
            setChildren([])
            setChildrenNumber(null)
        }
    };

    const handleOk = () => {
        setShowResumeModal(false);
    };
    const handleCancel = () => {
        setShowResumeModal(false);
        setIsChecked(false)
    };


    return (
        <div>
            <Modal open={showResumeModal} footer={false}>
                <div style={{textAlign: 'center', marginTop: '1rem'}}>
                    <h4>سابقه کار خیلی میتونه کمک کنه،</h4>
                    <h4>مطمئنی سابقه کار نداری؟</h4>
                    <div style={{margin: '2rem  0 1rem'}}>
                        <CustomButton onClick={handleOk} text={'بله'} ghost={true} width={100}/>
                        <CustomButton onClick={handleCancel} text={'خیر'} width={100} style={{marginRight: '15px'}}/>
                    </div>
                </div>
            </Modal>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={value} style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{marginBottom: '1rem'}}><CustomRadioButton isChecked={value} value={true} text={'بله'}
                                                                       width={150}/></div>

                <Row gutter={[12, 12]}>
                    <Col lg={24}>
                        <Select
                            size={'large'}
                            placeholder={'تعداد فرزند'}
                            value={
                                childrenNumber
                            }
                            onChange={handleChange}
                            options={[
                                {
                                    value: 1,
                                    label: '1 فرزند',
                                },
                                {
                                    value: 2,
                                    label: '2 فرزند',
                                },
                                {
                                    value: 3,
                                    label: 'بیش از 2 فرزند',
                                },
                            ]}
                        />
                    </Col>
                    {
                        children?.map(item => {
                            return <Col lg={12}>
                                <Select
                                    size={'large'}
                                    placeholder={` سن فرزند${' ' + item.number}`}
                                    // defaultValue={''}
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 1,
                                            label: 'کمتر از 18 سال',
                                        },
                                        {
                                            value: 2,
                                            label: 'بیشتر از 18 سال',
                                        },
                                    ]}
                                />
                            </Col>
                        })
                    }
                </Row>

                <div><CustomRadioButton isChecked={!value} value={false} text={'نه بچه ندارم'} width={150}/></div>
            </Radio.Group>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default NineteenthStep