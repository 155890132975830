import { Radio } from 'antd';
import './customRadioButton.scss'

const CustomRadioButton = ({text,value,width,isChecked})=>{

    return (
        <div className={'custom-radio-wrapper'} style={{width:`${width}px`,borderColor:`${isChecked ? '#32B8C5':'#ccc'}`}}>
            <Radio checked={isChecked} value={value}>{text}</Radio>
        </div>
    )
}

export default CustomRadioButton