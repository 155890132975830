import stepRenderer from "../helpers/stepRenderer";
import {questions} from "../helpers/questions"
import {useState} from "react";
import CustomButton from "../general/CustomButton";
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import './mainForm.scss'
import {Tooltip} from "antd";
import {Outlet, useLocation, useNavigate, useParams} from "react-router";
import CanadaFlag from '../../images/Canada-flag.svg'

const MainForm = () => {
    const [info, setInfo] = useState({})
    const navigate = useNavigate()
    const location = useLocation()
    const param = useParams()

    const nextPageHandler = (page) => {
        if (location.pathname.includes('partner')){
            navigate(`/questions/partner/${+param.step+1}`)
            // setPage(currentPage => currentPage + 1)
        }else{
            navigate(`/questions/${+param.step+1}`)
            // setPage(currentPage => currentPage + 1)
        }
    }

    const prevPageHandler = (page) => {
        if (+param.step > 1 && location.pathname.includes('partner')) {
            navigate(`/questions/partner/${+param.step-1}`)
            // setPage(currentPage => currentPage - 1)
        }else if (+param.step > 1 && !location.pathname.includes('partner')){
            navigate(`/questions/${+param.step-1}`)
            // setPage(currentPage => currentPage - 1)
        }
    }

    return (
        <div className={'main-form-container'}>
            <div className={'main-form-items-wrapper'}>
                <div className={'main-form-progress'}>
                    <div>
                        <img src={CanadaFlag} alt={'Canada flag'} style={{marginRight:'20px'}} />
                    </div>
                    <div style={{width:'100%'}}>
                        <progress id="progress" value="50" max="100"> 32%</progress>
                        {location.pathname.includes('partner') && <progress id="progress" value="50" max="100"> 32%</progress>}
                    </div>
                </div>
                <div className={'main-form-question'}>
                    <div className={'question-text'}> {+param.step}. {questions[+param.step - 1].text}</div>
                    {/*<div style={{width: '100%'}}>{stepRenderer(page)}</div>*/}
                    <div style={{width: '100%'}}><Outlet context={{info,setInfo}} /></div>
                    {/*<div style={{width: '100%'}}><CustomButton text={page===21 ? 'تایید نهایی':'مرحله بعد'} width={150}*/}
                    {/*                                           style={{marginTop: '1rem'}} onClick={nextPageHandler}/>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={'main-form-footer'}>
                <div className={'question-handler-container'}>
                    <Tooltip title={'سوال بعدی'}><div className={'next-question'} onClick={nextPageHandler}><DownOutlined/></div></Tooltip>
                    <Tooltip title={'سوال قبلی'}><div className={'prev-question'} onClick={prevPageHandler}><UpOutlined/></div></Tooltip>
                </div>
                <div className={'footer-question'}>{questions?.[+param.step]?.text}</div>
            </div>
        </div>
    )
}

export default MainForm