import {Col, Row, Select} from 'antd';
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const SeventhStep = ({question})=>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/8')
        }else{
            navigate('/questions/8')
        }
    }


    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    return (
        <div>
            <div>{question}</div>
            <Row>
                <Col lg={24}>
                <Select
                    size={'large'}
                    placeholder={'انتخاب نمره'}
                    defaultValue={6}
                    onChange={handleChange}
                    options={[
                        {
                            value: 5,
                            label: '5',
                        },
                        {
                            value: 6,
                            label: '6',
                        },
                        {
                            value: 7.5,
                            label: '7.5',
                        },
                    ]}
                />
                </Col>
            </Row>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default SeventhStep