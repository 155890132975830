import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const EighteenthStep = ({question}) =>{

    const [value, setValue] = useState('yes');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/19')
        }else{
            navigate('/questions/19')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={value}>
                <div><CustomRadioButton isChecked={value==='yes'} value={'yes'} text={'مجرد هستم'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'متاهل هستم'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'از همسرم رسما جدا شده ام'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'همسرم فوت شده است'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'در رابطه هستم ولی مدرک رسمی ندارم'} width={350} /></div>
            </Radio.Group>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default EighteenthStep