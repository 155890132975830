import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";

const FourteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([
        {
            id: 1,
            title: 'حسابداری و مالی',
            type: 'موفق',
            fromDate: '1392(2015)',
            toDate: '1393(2016)'
        },
        {
            id: 2,
            title: 'حسابداری و مالی',
            type: 'نامعلوم',
            fromDate: '1392(2015)',
            toDate: '1393(2016)'
        },
    ])
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/15')
        }else{
            navigate('/questions/15')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setShowModal(true)
        } else {
            setShowQuestion(true)
        }
    };

    const handleOk = () => {
        setShowModal(false);
        setValue('yes')
    };

    const handleCancel = () => {
        setShowModal(false);
        setValue(null)
    };

    const deleteListItemHandler = (id) => {
        let items = data.filter(item => item.id !== id)
        setData(items)
    }

    return (
        <div style={{textAlign: 'right'}}>
            <Modal onCancel={handleCancel} open={showModal} footer={false}>
                <div style={{textAlign: "center"}}>
                    <div style={{fontSize: '16px', color: '#686868', marginBottom: '1rem', fontWeight: 'bold'}}>این سوال
                        خیلی مهمه
                    </div>
                    <div style={{fontSize: '16px', color: '#686868', marginBottom: '1rem', fontWeight: 'bold'}}>مطمئنی
                        تاحالا برای کانادا اقدام نکردی؟
                    </div>
                </div>
                <div style={{textAlign: 'center', margin: '2rem 0 1rem'}}>
                    <CustomButton text={'بله'} ghost={true} width={120} onClick={handleOk}/>
                    <CustomButton text={'خیر'} width={120} onClick={handleCancel} style={{marginRight: '1rem'}}/>
                </div>
            </Modal>
            <div>{question}</div>
            {!showQuestion ? <Radio.Group onChange={onChange} value={value}>
                    <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'بله'} width={300}/></div>
                    <div><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                            text={'نه هیچ وقت! اولین باره تصمیمم جدی شده'} width={300}/></div>
                </Radio.Group> :
                <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'سال'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: '(2017) 1394',
                                    },
                                    {
                                        value: 6,
                                        label: '(2022) 1399',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'نوع پرونده'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'تحصیلی',
                                    },
                                    {
                                        value: 6,
                                        label: 'جاب آفر',
                                    },
                                    {
                                        value: 5,
                                        label: 'سرمایه گذاری',
                                    },
                                    {
                                        value: 6,
                                        label: 'توریستی',
                                    },
                                    {
                                        value: 5,
                                        label: 'اقامت دائم',
                                    },
                                    {
                                        value: 6,
                                        label: 'سایر',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'وضعیت'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'موفق',
                                    },
                                    {
                                        value: 6,
                                        label: 'ناموفق',
                                    },
                                    {
                                        value: 5,
                                        label: 'نامعلوم',
                                    },
                                    {
                                        value: 6,
                                        label: 'هنوز نتیجه اعلام نشده',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'سال اقدام'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: '(2017) 1394',
                                    },
                                    {
                                        value: 6,
                                        label: '(2022) 1399',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <div style={{textAlign: 'left', margin: '2rem 0 1rem'}}>
                        <CustomButton text={'افزودن اقدام جدید'} ghost={true} width={130} onClick={handleOk}/>
                    </div>
                    {data.length > 0 && <div
                        id="scrollableDiv"
                        style={{
                            direction: 'ltr',
                            minHeight: 'fit-content',
                            maxHeight: 150,
                            margin: '2rem 0',
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <List
                            dataSource={data}
                            bordered={false}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={'list-item-wrapper'}>
                                        <div>
                                            <div>{item.title}</div>
                                            <div><span style={{color: '#818181'}}>نتیجه: </span><span style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: '#656565'
                                            }}>{item.type}</span></div>
                                        </div>
                                        <div className={'delete-date-container'}>
                                            <div>
                                                <div>{item.fromDate}</div>
                                                <div>{item.toDate}</div>
                                            </div>
                                            <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                                <DeleteOutlined className={'delete-icon'}/></div>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>}
                </>
            }
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default FourteenthStep