import {Input} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const SecondStep = ({question})=>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/3')
        }else{
            navigate('/questions/3')
        }
    }

    return (
        <div>
            <div>{question}</div>
            <Input placeholder={'ایمیل خود را وارد کنید'} name={'email'} size={'large'} />
            <div style={{width: '100%'}}><CustomButton text={'مرحله بعد'} width={150}
                                                       style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default SecondStep