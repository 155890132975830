import {Input} from "antd";
import CustomButton from "../../general/CustomButton";
import {useOutletContext, useNavigate, useLocation} from "react-router";

const FirstStep = ({question}) => {

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()


    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/2')
        }else{
            navigate('/questions/2')
        }
    }

    return (
        <div>
            <div>{question}</div>
            <Input placeholder={'اسم خود را وارد کنید'} name={'name'} size={'large'}/>
            {/*<div style={{width: '100%'}}><CustomButton text={page === 21 ? 'تایید نهایی' : 'مرحله بعد'} width={150}*/}
            <div style={{width: '100%'}}><CustomButton text={ 'مرحله بعد'} width={150}
                                                       style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default FirstStep