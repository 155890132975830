import {Input} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const FourthStep = ({question})=>{

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/5')
        }else{
            navigate('/questions/5')
        }
    }

    return (
        <div>
            <div>{question}</div>
            <Input placeholder={'سن خود را وارد کنید'} name={'age'} size={'large'} />
            <div style={{width: '100%'}}><CustomButton text={'مرحله بعد'} width={150}
                                                       style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default FourthStep