import {AutoComplete, Input, Select} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const {Option} = Select

const ThirdStep = ({question}) => {

    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/4')
        }else{
            navigate('/questions/4')
        }
    }

    return (
        <div>
            <div>{question}</div>
            <Input.Group compact style={{direction: 'ltr'}}>
                <Select
                    defaultValue="+98"
                    size={'large'}
                    style={{
                        width: '20%',
                    }}
                >
                    <Option value="+98">+98</Option>
                    <Option value="+1">+1</Option>
                </Select>
                <Input placeholder={'شماره تلفن خود را وارد کنید'} name={'phoneNumber'} size={'large'}
                       style={{
                           width: '80%',
                       }}/>
            </Input.Group>
            <div style={{width: '100%'}}><CustomButton text={'مرحله بعد'} width={150}
                                                       style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default ThirdStep