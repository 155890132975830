import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";

const FifteenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([
        {
            id: 1,
            title: 'کانادا',
            fromDate: '1392(2015)',
            toDate: '1393(2016)'
        },
        {
            id: 2,
            title: 'آلمان',
            fromDate: '1392(2015)',
            toDate: '1393(2016)'
        },
    ])
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/16')
        }else{
            navigate('/questions/16')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setShowModal(true)
        } else {
            setShowQuestion(true)
        }
    };

    const handleOk = () => {
        setValue('yes')
    };

    const handleCancel = () => {
        setShowModal(false);
        setValue(null)
    };

    const deleteListItemHandler = (id) => {
        let items = data.filter(item => item.id !== id)
        setData(items)
    }

    return (
        <div style={{textAlign: 'right'}}>
            <div>{question}</div>
            {!showQuestion ? <Radio.Group onChange={onChange} value={value}>
                    <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'بله'} width={300}/></div>
                    <div><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                            text={'نه متاسفانه!!'} width={300}/></div>
                </Radio.Group> :
                <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={24}>
                            <Select
                                size={'large'}
                                placeholder={'انتخاب کشور'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'کانادا',
                                    },
                                    {
                                        value: 6,
                                        label: 'ترکیه',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'از سال'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: '(2017) 1394',
                                    },
                                    {
                                        value: 6,
                                        label: '(2022) 1399',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'تا سال'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: '(2017) 1394',
                                    },
                                    {
                                        value: 6,
                                        label: '(2022) 1399',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <div style={{textAlign: 'left', margin: '2rem 0 1rem'}}>
                        <CustomButton text={'افزودن اقامت جدید'} ghost={true} width={150} onClick={handleOk}/>
                    </div>
                    {data.length > 0 && <div
                        id="scrollableDiv"
                        style={{
                            direction: 'ltr',
                            minHeight: 'fit-content',
                            maxHeight: 150,
                            margin: '2rem 0',
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <List
                            dataSource={data}
                            bordered={false}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={'list-item-wrapper'}>
                                        <div>
                                            <div style={{fontWeight:'bold'}}>{item.title}</div>
                                        </div>
                                        <div className={'delete-date-container'}>
                                                <div>{item.fromDate}</div>
                                            <span style={{margin:'0 5px'}}>-</span>
                                                <div>{item.toDate}</div>
                                            <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                                <DeleteOutlined className={'delete-icon'}/></div>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>}
                </>
            }
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default FifteenthStep