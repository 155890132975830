import {useCallback, useState} from "react";
import CustomButton from "../../general/CustomButton";
import {Row, Col, Checkbox, Modal, Select, List} from 'antd'
import {DeleteOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router";

const previewText = (
    <>
    <div>
        ببین واقعیتش اینه که بعضی شغل ها تو کانادا کنترل شده هستن. ینی مجوز کار میخواد و از ایران نمیشه مستقیم بیای و اون کارو انجام بدی ولی بعضی رشته های دیگه هست که اگه توش مدرک و یا ساقه و یا هر جفتش رو داشته باشی از نظر مهاجرتی حتی شاید بشه خیلی راحت تر برات اقدام کنمو  زودتر هم به نتیجه برسه.
    </div>
        <br/>
    <div>
        به فهست شغل های زیر نگاه کن، آیا شغلی هست که توش سابقه کار داشته باشی و بتونی براش مدرک فنی حرفه اییا نامه رسمی از سازمانمعتبر ارائه بدی؟توجه داشته باش که شرکت یا سازمان مربوطه باید معتبر و ثبت شده باشه.
    </div>
        <br/>
    </>
)

const jobs = [
    {
        id:1,
        title:'هر نوع آشپز، کمک آشپز و مدیر رستوران',
        value:1,
    },
    {
        id:2,
        title:'آرایشگری و ناخن کاری',
        value:2,
    },
    {
        id:3,
        title:'هتلداری و یا کار مربوط به مهمان نوازی',
        value:3,
    },
    {
        id:4,
        title:'خیاطی',
        value:4,
    },
    {
        id:5,
        title:'مربی مهد کودک',
        value:5,
    },
    {
        id:6,
        title:'تعمیر خودرو',
        value:6,
    },
    {
        id:7,
        title:'طراحی سایت',
        value:7,
    },
    {
        id:8,
        title:'رانندگی ماشین سنگین',
        value:8,
    },
    {
        id:9,
        title:'عکاسی تبلیغاتی',
        value:9,
    },
    {
        id:10,
        title:'کار در فروشگاه های زنجیره ای',
        value:10,
    },
    {
        id:11,
        title:'طراح گرافیک و مدیر تبلیغات',
        value:11,
    },
    {
        id:12,
        title:'منشی',
        value:12,
    },
    {
        id:13,
        title:'سالمندیاری/کودکیاری',
        value:13,
    },
    {
        id:14,
        title:'مشاور املاک',
        value:14,
    },
    {
        id:15,
        title:'پرستار یا کمک پزشک یا دنانپزشک',
        value:15,
    },
    {
        id:16,
        title:'قصابی',
        value:16,
    },
    {
        id:17,
        title:'شیرینی پزی و نانوایی',
        value:17,
    },
    {
        id:18,
        title:'نجاری',
        value:18,
    },
    {
        id:19,
        title:'کشاوررزی و دامپروری',
        value:19,
    },
    {
        id:20,
        title:'گل فروشی و باغبانی',
        value:20,
    },
    {
        id:21,
        title:'در هیچ کدام از رشته های فوق سابه کاری ندارم',
        value:21,
    },
]




const EleventhStep=({question})=>{

    const [isPreview,setIsPreview]=useState(true)
    const [showModal,setShowModal]=useState(false)
    const [selectedValues,setSelectedValues]=useState(null)
    const [selectedId,setSelectedId]=useState(null)
    const [data,setData]= useState([
        {
            id:1,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:2,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
        {
            id:3,
            title:'حسابداری و مالی',
            type:'تمام وقت',
            fromDate:'1392(2015)',
            toDate:'1393(2016)'
        },
    ])
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/12')
        }else{
            navigate('/questions/12')
        }
    }



    const renderModalTitle = ()=>{
        const item = jobs.find(item=>item.id===selectedId)
        return item?.title
    }

    const previewHandler = ()=>{
        setIsPreview(false)
    }

    const onChangeGroup = (checkedValues,id) => {
        console.log(checkedValues);
        setSelectedValues(checkedValues)
        // setSelectedCheckboxId(id)
        // if(checkedValues){
        //     setShowModal(true)
        // }
    };

    const onChange = (e,id)=>{
        console.log('eeee',e,id)
        setSelectedId(id)
        if (e.target.checked) {
            setShowModal(true)
        }
    }

    const handleOk = () => {
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedId(null)
    };

    const deleteListItemHandler = (id) =>{
        let items = data.filter(item => item.id !== id)
        setData(items)
    }

    const checkIsChecked = (id)=>{

    }


    return (
        <div style={{direction:'rtl'}}>
            <Modal title={renderModalTitle()} onCancel={handleCancel} open={showModal} footer={false}>
                <div style={{fontSize:'12.5px',color:'#686868',marginBottom:'1rem'}}>لطفا اطلاعات زیر را کامل کن</div>
                <Row gutter={[12,12]}>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'نوع مدرک'}
                            // defaultValue={6}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 5,
                                    label: 'گواهینامه',
                                },
                                {
                                    value: 6,
                                    label: 'دیپلم فنی حرفه ای',
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'تاریخ اخذ'}
                            // defaultValue={6}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 5,
                                    label: '(2017) 1394',
                                },
                                {
                                    value: 6,
                                    label: '(2022) 1399',
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <div style={{fontSize:'12.5px',color:'#686868',margin:'1rem 0'}}>سابقه کار</div>
                <Row gutter={[12,12]}>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'از سال'}
                            // defaultValue={6}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 5,
                                    label: 'گواهینامه',
                                },
                                {
                                    value: 6,
                                    label: 'دیپلم فنی حرفه ای',
                                },
                            ]}
                        />
                    </Col>
                    <Col lg={12}>
                        <Select
                            size={'large'}
                            placeholder={'تا سال'}
                            // defaultValue={6}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 5,
                                    label: '(2017) 1394',
                                },
                                {
                                    value: 6,
                                    label: '(2022) 1399',
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <div style={{textAlign:'left',margin:'2rem 0 1rem'}}>
                <CustomButton text={'ثبت'} ghost={true} width={120} onClick={handleOk} />
                </div>
            </Modal>
            {isPreview ? <div>
                {previewText}
                <div style={{textAlign:'left',marginBottom:'2rem'}}>
                <CustomButton text={'فهرست شغل ها'} width={130} ghost={true} onClick={previewHandler} />
                </div>
            </div>:
                <Checkbox.Group
                    style={{
                        width: '100%',
                    }}
                    onChange={onChangeGroup}
                >
                <Row>
                    {jobs.map(item=>{
                        return <Col lg={12} key={item.id}>
                            <Checkbox value={item.value}
                                      onChange={(e)=>onChange(e,item.id)}
                            >{item.title}</Checkbox>
                        </Col>
                    })}
                </Row>
                </Checkbox.Group>
            }
            {data.length > 0 && !isPreview && <div
                id="scrollableDiv"
                style={{
                    direction:'ltr',
                    height: 100,
                    marginTop: '1rem',
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
            >
                <List
                    dataSource={data}
                    bordered={false}
                    renderItem={(item) => (
                        <List.Item>
                            <div className={'list-item-wrapper'}>
                                <div>{item.title}{" " + `(${item.type})`}</div>
                                <div className={'delete-date-container'}>
                                    <div>{item.fromDate + " - " + item.toDate}</div>
                                    <div id={'delete'} onClick={()=>deleteListItemHandler(item.id)}><DeleteOutlined className={'delete-icon'}/></div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>}
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default EleventhStep