import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, Radio, Row} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const TwentiethStep = ({question}) =>{

    const [value, setValue] = useState('yes');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/21')
        }else{
            navigate('/questions/21')
        }
    }
    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={value}>
                <Row gutter={[12,12]}>
                <Col lg={24}><CustomRadioButton isChecked={value==='yes'} value={'yes'}
                                        text={'نه من اصلا شرایط زندگیم طوری نیست که بتونم تنهاشون بذارم یا حتی خودم تنها اقدام کنم'}
                                         /></Col>
                <Col lg={24}><CustomRadioButton isChecked={value==='no'} value={'no'}
                                        text={'نمیدونم خب سخته ولی برای گرفتن ویزا هرکاری به نفعم باشه و پیشنهاد بدی انجام میدم'}
                                         /></Col>
                </Row>
            </Radio.Group>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default TwentiethStep