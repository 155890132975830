import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const FifthStep = ({question}) =>{

    const [value, setValue] = useState('english');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/6')
        }else{
            navigate('/questions/6')
        }
    }

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={value}>
            <div><CustomRadioButton isChecked={value==='english'} value={'english'} text={'انگلیسی'} width={150} /></div>
            <div><CustomRadioButton isChecked={value==='french'} value={'french'} text={'فرانسه'} width={150} /></div>
            </Radio.Group>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                       style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default FifthStep