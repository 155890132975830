import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, Radio, Row} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const TwentyOneStep = ({question}) =>{

    const [value, setValue] = useState('yes');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            // navigate('/questions/partner/21')
            setValue('no')
            navigate('/buy-service')
        }else if (!location.pathname.includes('partner') && value==='yes'){
            navigate('/partner')
        }else if (value==='no'){
            navigate('/buy-service')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={value}>
                <Row gutter={[12,12]}>
                    <Col lg={24}><CustomRadioButton isChecked={value==='yes'} value={'yes'}
                                                    text={'حتما'}
                    /></Col>
                    <Col lg={24}><CustomRadioButton isChecked={value==='no'} value={'no'}
                                                    text={'نه تو فعلا با همین اطلاعات ببین چیکار میتونی بکنی'}
                    /></Col>
                </Row>
            </Radio.Group>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={value==='yes'?'مرحله بعد':'تایید نهایی'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default TwentyOneStep