import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Radio} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const EighthStep = ({question}) =>{

    const [value, setValue] = useState('yes');
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/9')
        }else{
            navigate('/questions/9')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div style={{textAlign:'right'}}>
            <div>{question}</div>
            <Radio.Group onChange={onChange} value={value}>
                <div><CustomRadioButton isChecked={value==='yes'} value={'yes'} text={'عمرا بتونم بالای 4 بگیرم'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'شاید اگه بخونم بتونم بین 4 تا 6 بگیرم'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'قطعا یکم بخونم 6 به بالا میشم'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'اگه بخونم شاید حتی 7 یا بالاتر هم بگیرم'} width={350} /></div>
                <div><CustomRadioButton isChecked={value==='no'} value={'no'} text={'اصلا تو چیکار داری، هر نمره ای بگی من میگیرم!'} width={350} /></div>
            </Radio.Group>
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default EighthStep