import CustomRadioButton from "../../general/CustomRadioButton";
import {useState} from "react";
import {Col, List, Modal, Radio, Row, Select} from "antd";
import CustomButton from "../../general/CustomButton";
import {DeleteOutlined} from "@ant-design/icons";
import './SeventeenthStep.scss'
import {useLocation, useNavigate, useOutletContext} from "react-router";

const SeventeenthStep = ({question}) => {

    const [value, setValue] = useState(null);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([
        {
            id: 1,
            title: 'پدر، مادر، خواهر، برادر',
            type: 'اقامت موقت',
            location: 'نمی دونم',
            owner: 'بله'
        },
        {
            id: 2,
            title: 'فامیل دور',
            type: 'پناهنده',
            location: 'BC',
            owner: 'خیر'
        },

    ])
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/18')
        }else{
            navigate('/questions/18')
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'no') {
            setShowModal(true)
        } else {
            setShowQuestion(true)
        }
    };

    const handleOk = () => {
        setValue('yes')
    };

    const handleCancel = () => {
        setShowModal(false);
        setValue(null)
    };

    const deleteListItemHandler = (id) => {
        let items = data.filter(item => item.id !== id)
        setData(items)
    }

    return (
        <div style={{textAlign: 'right'}}>
            <div>{question}</div>
            {!showQuestion ? <Radio.Group onChange={onChange} value={value}>
                    <div><CustomRadioButton isChecked={value === 'yes'} value={'yes'} text={'بله'} width={300}/></div>
                    <div><CustomRadioButton isChecked={value === 'no'} value={'no'}
                                            text={'نه متاسفانه!!'} width={300}/></div>
                </Radio.Group> :
                <>
                    <div style={{fontSize: '12.5px', color: '#686868', marginBottom: '1rem'}}>لطفا اطلاعات زیر را کامل
                        کن
                    </div>
                    <Row gutter={[12, 12]}>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'نوع رابطه'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'پدر، مادر، خواهر، برادر',
                                    },
                                    {
                                        value: 6,
                                        label: 'فامیل درجه دو',
                                    },
                                    {
                                        value: 6,
                                        label: 'فامیل دور',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'وضعیت اقامت'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'اقامت موقت',
                                    },
                                    {
                                        value: 6,
                                        label: 'اقامت دائم',
                                    },
                                    {
                                        value: 6,
                                        label: 'سیتیزن',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'استان محل اقامت'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'BC',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={12}>
                            <Select
                                size={'large'}
                                placeholder={'مالکیت بیزنس در کانادا'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'بله',
                                    },
                                    {
                                        value: 6,
                                        label: 'خیر',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <div style={{textAlign: 'left', margin: '1rem 0 2rem'}}>
                        <CustomButton text={'افزودن فرد جدید'} ghost={true} width={150} onClick={handleOk}/>
                    </div>
                    {/*<div className={'person-card-wrapper'}>*/}
                    <Row gutter={[12,12]} style={{margin:'1rem 0'}}>
                        {
                            data.map(item => {
                                return <Col key={item.id} lg={12}> <div className={'family-card'}>
                                    <div className={'family-card-title-container'}>
                                        <div className={'family-title'}>{item.title}</div>
                                        <div id={'delete'} onClick={() => deleteListItemHandler(item.id)}>
                                            <DeleteOutlined className={'delete-icon'}/></div>
                                    </div>
                                    <div style={{marginTop:'10px'}}>وضعیت: <span style={{fontSize:'15px',fontWeight:'bold'}}>{item.type}</span></div>
                                    <div style={{marginTop:'10px'}}>محل اقامت: <span style={{fontSize:'15px',fontWeight:'bold'}}>{item.location}</span></div>
                                    <div style={{marginTop:'10px'}}>مالکیت بیزنس: <span style={{fontSize:'15px',fontWeight:'bold'}}>{item.owner}</span></div>
                                </div>
                                </Col>
                            })
                        }
                    </Row>
                    {/*</div>*/}
                </>
            }
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
        </div>
    )
}

export default SeventeenthStep