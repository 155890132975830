import {useState} from "react";
import {Checkbox, Col, Row, Select} from "antd";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import CustomButton from "../../general/CustomButton";

const TwelfthStep = ({question}) => {

    const [data, setData] = useState([
        {
            id: 1,
            title: 'تمکن بانکی',
            value: 1,
            isChecked: false
        },
        {
            id: 2,
            title: 'ملک و زمین',
            value: 2,
            isChecked: false
        },
        {
            id: 3,
            title: 'سهام',
            value: 3,
            isChecked: false
        },
        {
            id: 4,
            title: 'سرقفلی یا جواز',
            value: 4,
            isChecked: false
        },
        {
            id: 5,
            title: 'شرکت',
            value: 5,
            isChecked: false
        },
        {
            id: 6,
            title: 'گردش مالی ماهیانه',
            value: 6,
            isChecked: false
        },
        {
            id: 7,
            title: 'دارایی غیرنقدی',
            value: 7,
            isChecked: false
        },
        {
            id: 8,
            title: 'هیچکدام',
            value: 8,
            isChecked: false
        },
    ])
    const {info, setInfo} = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const nextPageHandler = () => {
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/13')
        }else{
            navigate('/questions/13')
        }
    }

    const onChangeCheck = (e,id) => {
    let newState = data.map(item=> {
        if (item.id===id) {
            return {...item,isChecked:!item.isChecked}
        }
        return item
    })
        setData(newState)
    }

    return (
        <Row gutter={[12, 6]}>
            {data.map(item => {
                return (
                    <Row style={{width:'100%'}} gutter={[12, 6]}>
                        <Col lg={8}>
                            <Checkbox checked={item.isChecked}
                                      onChange={(e) => onChangeCheck(e, item.id)}>{item.title}</Checkbox>
                        </Col>
                        {item.id === 1 && item.isChecked && <Col lg={16}><Select
                            size={'large'}
                            placeholder={'ارزش'}
                            // defaultValue={6}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 5,
                                    label: 'تا 500 میلیون',
                                },
                                {
                                    value: 6,
                                    label: '500 میلیون تا 1 میلیارد',
                                },
                            ]}
                        /></Col>}
                        {item.id === 2 && item.isChecked && <>
                            <Col lg={8}>
                                <Select
                                    size={'large'}
                                    placeholder={'متراژ'}
                                    // defaultValue={6}
                                    // onChange={handleChange}
                                    options={[
                                        {
                                            value: 5,
                                            label: 'تا 100 متر',
                                        },
                                        {
                                            value: 6,
                                            label: 'تا 200 متر',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col lg={8}><Select
                                size={'large'}
                                placeholder={'ارزش'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'تا 500 میلیون',
                                    },
                                    {
                                        value: 6,
                                        label: '500 میلیون تا 1 میلیارد',
                                    },
                                ]}
                            /></Col>
                        </>}
                        {item.id === 4 && item.isChecked && <>
                            <Col lg={8}>
                                <Select
                                    size={'large'}
                                    placeholder={'سابقه'}
                                    // defaultValue={6}
                                    // onChange={handleChange}
                                    options={[
                                        {
                                            value: 5,
                                            label: '3-1 سال',
                                        },
                                        {
                                            value: 6,
                                            label: '5-3 سال',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col lg={8}><Select
                                size={'large'}
                                placeholder={'نوع فعالیت'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'فعال است',
                                    },
                                    {
                                        value: 6,
                                        label: 'فعالیت ندارد',
                                    },
                                ]}
                            /></Col>
                        </>}
                        {item.id === 5 && item.isChecked && <>
                            <Col lg={8}>
                                <Select
                                    size={'large'}
                                    placeholder={'تعداد سال فعالیت'}
                                    // defaultValue={6}
                                    // onChange={handleChange}
                                    options={[
                                        {
                                            value: 5,
                                            label: '3-1 سال',
                                        },
                                        {
                                            value: 6,
                                            label: '5-3 سال',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col lg={8}><Select
                                size={'large'}
                                placeholder={'میانگین گردش سالیانه'}
                                // defaultValue={6}
                                // onChange={handleChange}
                                options={[
                                    {
                                        value: 5,
                                        label: 'تا 500 میلیون',
                                    },
                                    {
                                        value: 6,
                                        label: '500 میلیون تا 1 میلیارد',
                                    },
                                ]}
                            /></Col>
                        </>}

                    </Row>
                )
            })}
            <div style={{width: '100%',textAlign:"left"}}><CustomButton text={'مرحله بعد'} width={150}
                                                                        style={{marginTop: '1rem'}} onClick={nextPageHandler}/>
            </div>
                </Row>
                )
            }

                export default TwelfthStep